import "./css/App.css";
import NotFound from "./NotFound";
import Admin from "./Admin";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Auth from "./Auth";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Admin />} />
        <Route path="/login" element={<Auth />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;