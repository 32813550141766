import React, { Component } from "react";
import Spinner from "./Spinner"; // Include your Spinner component
import "./css/GiftBatches.css";
import LogoHeader from "./LogoHeader2";
import axios from "axios";
import { read, utils } from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchUsers } from "./userService";
import AddUserForm from "./AddUserForm";
import * as XLSX from "xlsx";
import UpdateUserForm from "./UpdateUserForm";

const NEW_USER = {
  "given_name": "",
  "family_name": "",
  "email": "",
  "password": "",
  "security": "User"
}

class Admin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      error: null,
      isLoading: false,
      screenWidth: window.innerWidth,
      expandedRowID: null,
      expandedRowType: null,
      editedUser: {},
      userToAdd: NEW_USER,
      securityOptions: ["Admin", "User"],
      addUserError: null
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const users = await fetchUsers();

      this.setState({ users, isLoading: false });
    } catch (error) {
      this.setState({ error: error.message, isLoading: false });
    }
  }

  updateExpandRow = (e, email, type) => {
    e.preventDefault();

    if (this.state.expandedRowID === email) {
      this.setState({
        expandedRowID: null,
      });

      return;
    }

    // Find the item with matching receiptID
    const foundUser = this.state.users.find(
      (item) => item.email === email
    );

    console.log(foundUser);

    if (foundUser) {
      foundUser["security"] = foundUser.Groups[0];

      this.setState({
        expandedRowID: email,
        editedUser: foundUser,
        expandedRowType: type
      }, ()=> console.log(this.state));
    }
  };

  handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ category: selectedValue });
  };


addUser = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { userToAdd } = this.state;

    if (!userToAdd.email || !userToAdd.password || !userToAdd.given_name || !userToAdd.family_name || !userToAdd.security) {
      this.setState({ addUserError: "Please fill in all fields", isLoading: false });
      return;
    }

    const payload = {
      "username": userToAdd.email,
      "password": userToAdd.password,
      "first_name": userToAdd.given_name,
      "last_name": userToAdd.family_name,
      "Groups": [userToAdd.security],
    }

    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.ok) {
          const users = await fetchUsers();
          toast.success("User added!");
          this.setState({ users: users, expandedRowID: null, userToAdd: NEW_USER, addUserError: null });
        } else {
          toast.error("Error adding user!");
          this.setState({ addUserError: "Error adding user!" });
        }
      })
      .catch((error) => {
        toast.error("Error adding user:", error);
        this.setState({ addUserError: "Error adding user:", error });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateUser = async (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { editedUser } = this.state;

    const payload = {
      "username": editedUser.email,
      "password": editedUser.password,
      "first_name": editedUser.given_name,
      "last_name": editedUser.family_name,
      "Groups": [editedUser.security],
    }

    fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response.ok) {
          const users = await fetchUsers();
          toast.success("User updated!");
          this.setState({ users: users, expandedRowID: null });
        } else {
          toast.error("Error updating user!");
        }
      })
      .catch((error) => {
        toast.error("Error updating user:", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateStatusResult = async (response, status) => {
    if (response.ok) {
      toast.success(`User ${status}d successfully!`);

      const users = await fetchUsers();
      this.setState({ users: users });
    } else {
      const error = await response.json();
      throw new Error(error.message || `Failed to ${status} user.`);
    }
  };

  updateStatus = async (e, email, status) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    try {
      let response;
      if (status === "enable") {
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/enable`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ username: email }),
          }
        );

        await this.updateStatusResult(response, status);
      } else if (status === "disable") {
        response = await fetch(
          `${process.env.REACT_APP_API_URL}/users?username=${email}`,
          {
            method: "DELETE", // Use POST instead of DELETE for consistency
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        await this.updateStatusResult(response, status);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleUserInfoChange = (identifier, e) => {
    const { value } = e.target;
    const updatedNewUser = { ...this.state.editedUser, [identifier]: value };
    this.setState({ editedUser: updatedNewUser });
  };

  handleAddUserChange = (identifier, e) => {
    const { value } = e.target;
    const updatedUser = { ...this.state.userToAdd, [identifier]: value };
    this.setState({ userToAdd: updatedUser });
  };

  exportData = (e) => {
    e.preventDefault();
    this.setState({isLoading: true});

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/users`,
    }).then((response) => {
      const results = response.data.all_users_details;
      const finalResults = []

      const filteredResults = results.filter(element => {
        return element.given_name !== "Apollo";
      });

      filteredResults.forEach(element => {
        const groups = element["Groups"];
        if (Array.isArray(groups)) {
          element["Security"] = groups.join(", ");
        }

        finalResults.push({
          "Internal ID": element.sub,
          "Username": element.email,
          "Email Verified?": element.email_verified ? "Yes": "No",
          "Firstname": element.given_name,
          "Lastname": element.family_name,
          "Status": element.Status? "Enabled": "Disabled",
          "Security": element.Security
        });
      });

      // Create a new workbook
      const wb = XLSX.utils.book_new();

      // Convert attendees data to worksheet
      const ws = XLSX.utils.json_to_sheet(finalResults);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, "Users");

      // Generate a unique filename for the Excel file
      const filename = `users_${new Date().toISOString()}.xlsx`;

      // Save the Excel file and trigger download
      XLSX.writeFile(wb, filename);

      this.setState({isLoading: false});
    });
  };



  render() {
    const { error, isLoading, users, editedUser, securityOptions, userToAdd, addUserError } = this.state;

    const filteredUsers = users.filter((user) => user.given_name !== "Apollo");
    return (
      <>
        <LogoHeader />

        <main>
        <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Big Sisters User Administration</span>
            </h1>
            <AddUserForm
                isLoading={isLoading}
                toast={toast}
                userToAdd={userToAdd}
                handleAddUserChange={this.handleAddUserChange}
                addUser={this.addUser}
                securityOptions={securityOptions}
                addUserError={addUserError}
            />
          </section>

          <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>List of Users</span>
            </h1>
            <form className="form-validate">
                  <p>
                    <button onClick={this.exportData}>
                      Export ALL Users As Excel
                    </button>
                  </p>
                </form>
            <div className="cart-items-wrap">
              <table className="cart-items">
                <thead>
                  <tr>
                    <td className="cart-ttl">Name</td>
                    <td className="cart-ttl">Email</td>
                    <td className="cart-ttl">Status</td>
                    <td className="cart-ttl">Security</td>
                    <td className="cart-del">&nbsp;</td>
                    <td className="cart-del">&nbsp;</td>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers
                    .sort((a, b) => {
                      const a_name = `${a.given_name} ${a.family_name}`;
                      const b_name = `${b.given_name} ${b.family_name}`;

                      return a_name.localeCompare(b_name);
                    })
                    .map((user, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            {user.given_name} {user.family_name}
                          </td>
                          <td>{user.email}</td>

                          <td>{user.Status ? <span style={{color: "#fcb61c"}}>Enabled</span> :  <span style={{color: "#54585a"}}>DISABLED</span>}</td>
                          <td>{user.Groups.join(", ")}</td>
                          <>
                            {!user.Status ? (
                              <td className="cart-del">
                                <a
                                  className="cart-add"
                                  href="/"
                                  onClick={(e) => {
                                    this.updateStatus(e, user.sub, "enable");
                                  }}
                                  role="button"
                                >
                                  {"\u00A0"}
                                </a>
                              </td>
                            ) : (
                              <td className="cart-del">
                                <a
                                  className="cart-remove"
                                  href="/"
                                  onClick={(e) => {
                                    this.updateStatus(e, user.sub, "disable");
                                  }}
                                  role="button"
                                >
                                  {"\u00A0"}
                                </a>
                              </td>
                            )}
                          </>

                          <td className="cart-del">
                            <a
                              className="cart-edit"
                              href="/"
                              onClick={(e) => {
                                this.updateExpandRow(e, user.email, "User");
                              }}
                              role="button"
                            >
                              {"\u00A0"}
                            </a>
                          </td>
                        </tr>
                        
                      {this.state.expandedRowID === user.email && (
                        <>
                        <tr className="notes-row">
                          <td colSpan="5">
                            {this.state.expandedRowType === "User" && (
                              <UpdateUserForm
                                isLoading={isLoading}
                                toast={toast}
                                editedUser={editedUser}
                                handleUserInfoChange={this.handleUserInfoChange}
                                updateUser={this.updateUser}
                                securityOptions={securityOptions}
                              />
                            )}
                          </td>
                        </tr>
                        </>

                      )}
                      </React.Fragment>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
          <ToastContainer />
          {/* Display spinner when loading */}
          {isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default Admin;