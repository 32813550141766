import React, { useEffect } from "react";
import logo from "./img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";


function LogoHeader2() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("accessToken")
  const security = localStorage.getItem("security")

  useEffect(() => {
    if (!token || !security || !security.includes("Admin")) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("prevUrl", location.pathname);
      navigate(`/login?${currentUrl.searchParams}`);
    }
  }, [token, location, navigate]);

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem('accessToken', '');

    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set("prevUrl", location.pathname);
    navigate(`/login?${currentUrl.searchParams}`);
  };

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
          <li>
              <a href="/">
              <FontAwesomeIcon icon={faPeopleGroup} />&nbsp;
                <span className="shop-menu-ttl">Admin</span>
              </a>
            </li>
            {token && <>
              <li>
              <FontAwesomeIcon icon={faEllipsisVertical} style={{color: "#b0b1b3"}} />&nbsp;
                <span className="shop-menu-ttl"></span>
            </li>
            <li className="stylization">
              <a onClick={handleLogout} style={{cursor: "pointer"}}>
              <FontAwesomeIcon icon={faSignOut} />&nbsp;
                <span className="shop-menu-ttl">Log Out</span>
              </a>
            </li>            
            </>}
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader2;