import React, { Component } from "react";
import Form from "react-bootstrap/Form";

class AddUserForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showPassword: false };
  }

  render() {
    const {
        userToAdd,
      isLoading,
      addUserError,
      addUser,
      handleAddUserChange,
      securityOptions,
    } = this.props;

    return (
      <div className="auth-wrap">
        <div className="auth-col">
        {addUserError && (
            <div className="err404">
              <p className="err404-search">{addUserError}</p>
            </div>
          )}
          
          <form onSubmit={(e) => e.preventDefault()} className="login">
          <p className="contactform-field contactform-text">
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                id="email"
                name="email"
                value={userToAdd.email}
                onChange={(e) => handleAddUserChange("email", e)}
              />
            </p>
            <p className="contactform-field contactform-text">
              <label htmlFor="given_name">First Name:</label>
              <input
                type="text"
                id="given_name"
                name="given_name"
                value={userToAdd.given_name}
                onChange={(e) => handleAddUserChange("given_name", e)}
              />
            </p>
            <p className="contactform-field contactform-text">
              <label htmlFor="family_name">Last Name:</label>
              <input
                type="text"
                id="family_name"
                name="family_name"
                value={userToAdd.family_name}
                onChange={(e) => handleAddUserChange("family_name", e)}
              />
            </p>
            <p className="contactform-field contactform-text">
              <label htmlFor="password">Password:</label>
              <input
                type={this.state.showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={userToAdd.password}
                onChange={(e) => handleAddUserChange("password", e)}
              />

              <label htmlFor="show-password">Show Password:</label>
              <input
                type="checkbox"
                id="show-password"
                checked={this.state.showPassword}
                onChange={(e) => {
                  this.setState({ showPassword: !this.state.showPassword });
                }}
              />
            </p>

            <p className="contactform-field contactform-text">
              <label className="contactform-label" htmlFor="security">
                Security:
              </label>
              <span className="contactform-input">
                <Form.Control
                  id="security"
                  as="select"
                  value={userToAdd.security}
                  onChange={(e) => handleAddUserChange("security", e)}
                >
                  {securityOptions.map((option, index) => (
                    <>
                      <option key={index} value={option}>
                        {option}
                      </option>
                    </>
                  ))}
                </Form.Control>
              </span>
            </p>

            <p className="auth-submit">
              <button onClick={addUser} disabled={isLoading}>
                Add User
              </button>
            </p>
          </form>


        </div>
      </div>
    );
  }
}

export default AddUserForm;