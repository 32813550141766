import axios from "axios";

export const fetchUsers =  async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users`);

      return response.data.all_users_details;
    } catch (error) {
      return [];
    }
  }
